// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

// https://github.com/quasarframework/quasar/issues/2233 => for resizeObserver issue

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
    dsn:
        SENTRY_DSN ||
        'https://57d39053c15a40efbd25895e29e84d9a@o869450.ingest.sentry.io/5824228',
    ignoreErrors: [
        'ResizeObserver loop limit exceeded',
        'No available storage method found',
    ],
    ignoreUrls: ['http://localhost:3000/'],
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
});
