import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import localforage from 'localforage';
import dynamic from 'next/dynamic';
import styles from 'styles/components/header.module.scss';
import Link from './link';

const MobileMenuHandler = dynamic(() => import('./mobileMenuHandler'), {
    ssr: false,
});

const Nav = dynamic(() => import('./nav'), {
    ssr: false,
    // loading: () => <div style={{ height: '35px ' }} />,
});

const NavMobile = dynamic(() => import('./navMobile'), {
    ssr: false,
});

const RgpdDialog = dynamic(() => import('./rgpdDialog'), {
    ssr: false,
});

const Header = () => {
    const router = useRouter();
    const [isRgpd, setIsRgpd] = useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    useEffect(() => {
        const handleRGPD = () => {
            const isRgpdRoute = ['/', '/marques-voiture'];
            localforage.getItem('isRgpd').then((value) => {
                if (!value) {
                    // if no value in indexeddb proceed to check the route
                    setIsRgpd(!!isRgpdRoute.includes(router.asPath));
                } else {
                    // if isRgpd is set on indexeddb, do not show dialog
                    setIsRgpd(false);
                }
            });
        };
        const handleLocationChange = () => {
            // close mobile navigation
            setIsMobileMenuOpen(false);
        };

        handleRGPD();
        handleLocationChange();
    }, [router.asPath]);
    const handleCloseRgpdDialog = () => {
        setIsRgpd(false);
        localforage.setItem('isRgpd', true);
    };

    return (
        <>
            {isRgpd && <RgpdDialog handleCloseRgpdDialog={handleCloseRgpdDialog} />}
            <header className={styles.root}>
                <div className={styles.headerTop}>
                    <Link href="/" aria-label="visiter page accueil">
                        <p className={styles.branding}>soeezAuto</p>
                    </Link>
                    <div>
                        <MobileMenuHandler
                            isMobileMenuOpen={isMobileMenuOpen}
                            setIsMobileMenuOpen={setIsMobileMenuOpen}
                        />
                    </div>
                </div>
                <Nav />
                <NavMobile isMobileMenuOpen={isMobileMenuOpen} />
            </header>
        </>
    );
};

export default Header;
