import { all, call, put, takeLatest } from 'redux-saga/effects';
import localforage from 'localforage';
import {
    CLIENT_LOG,
    LOGOUT_TOKEN_EXPIRED,
    CHECK_ONLINE_STATUS_ERROR,
    GET_MODEL_VERSIONS_WITH_TRIMS,
    GET_MODEL_VERSIONS_WITH_TRIMS_OK,
    GET_MODEL_VERSIONS_WITH_TRIMS_ERROR,
    GET_PREVIOUS_MODELS,
    GET_PREVIOUS_MODELS_INIT,
    GET_PREVIOUS_MODELS_OK,
    GET_PREVIOUS_MODELS_ERROR,
    GET_MODEL,
    GET_MODEL_INIT,
    GET_MODEL_OK,
    GET_MODEL_ERROR,
    GET_MODEL_TRIMS,
    GET_MODEL_TRIMS_INIT,
    GET_MODEL_TRIMS_OK,
    GET_MODEL_TRIMS_ERROR,
    GET_MODELS_WITH_4X4,
    GET_MODELS_WITH_4X4_OK,
    GET_MODELS_WITH_4X4_ERROR,
    GET_MODELS_WITH_HYBRID_ELECTRIC,
    GET_MODELS_WITH_HYBRID_ELECTRIC_OK,
    GET_MODELS_WITH_HYBRID_ELECTRIC_ERROR,
} from 'store/actions';
import { apiQl, errorParserGraphql } from 'lib/functions';
import { getBaseDate } from 'tools/functions';

function* getModelVersionsWithTrims(action) {
    const queryQl = `query getModelVersionsWithTrims(
        $modelId: String!
        $isActive: Boolean!
        $trims: [String!]
    ) {
        versions(
            model: $modelId,
            isActive: $isActive
            trims_list:$trims
        )   {
    	        id
            }
    }`;

    const variables = {
        modelId: action.values.modelId,
        isActive: true,
        trims: [...action.values.trims],
    };
    try {
        const data = yield call(apiQl, queryQl, variables);
        if (data.errors) {
            yield put({
                type: GET_MODEL_VERSIONS_WITH_TRIMS_ERROR,
                data: errorParserGraphql(data.errors),
            });
            yield put({
                type: CLIENT_LOG,
                data: {
                    message: errorParserGraphql(data.errors),
                    action: GET_MODEL_VERSIONS_WITH_TRIMS,
                },
            });
        } else {
            yield put({
                type: GET_MODEL_VERSIONS_WITH_TRIMS_OK,
                data: data.data.versions,
            });
        }
    } catch (error) {
        const isOffline = !!(
            error.response === undefined || error.code === 'ECONNABORTED'
        );
        if (error.response.status === 401) {
            yield put({
                type: LOGOUT_TOKEN_EXPIRED,
            });
        } else if (isOffline) {
            // check if offline event already fired
            localforage.getItem('offline-event-fired').then((value) => {
                if (value === null) {
                    localforage.setItem('offline-event-fired', true);
                }
            });
            yield put({
                type: CHECK_ONLINE_STATUS_ERROR,
                isOnline: false,
            });
        }
    }
}

function* getPreviousModels(action) {
    const queryQl = `query getPreviousModels(
        $model: String!
    ){
        models(
            isActive: false
            _order: {createdAt: "DESC"}
            model: $model
        ){
            id
            model
            versions(
                isActive: true
                _order:{prices_price: "ASC"}
            ) {
                id
                version
                prices(
                    _order: {createdAt: "DESC"}
                ) {
                    edges {
                        node {
                            id
                            createdAt
                            price
                            promo
                        }
                    }
                }
            }
        }
    }`;

    const variables = {
        model: action.values.model,
    };
    try {
        yield put({
            type: GET_PREVIOUS_MODELS_INIT,
        });

        const data = yield call(apiQl, queryQl, variables);
        if (data.errors) {
            yield put({
                type: GET_PREVIOUS_MODELS_ERROR,
                data: errorParserGraphql(data.errors),
            });
            yield put({
                type: CLIENT_LOG,
                data: {
                    message: errorParserGraphql(data.errors),
                    action: GET_PREVIOUS_MODELS,
                },
            });
        } else {
            yield put({
                type: GET_PREVIOUS_MODELS_OK,
                data: data.data.models,
            });
        }
    } catch (error) {
        const isOffline = !!(
            error.response === undefined || error.code === 'ECONNABORTED'
        );
        if (error.response.status === 401) {
            yield put({
                type: LOGOUT_TOKEN_EXPIRED,
            });
        } else if (isOffline) {
            // check if offline event already fired
            localforage.getItem('offline-event-fired').then((value) => {
                if (value === null) {
                    localforage.setItem('offline-event-fired', true);
                }
            });
            yield put({
                type: CHECK_ONLINE_STATUS_ERROR,
                isOnline: false,
            });
        }
    }
}

function* getModel(action) {
    const queryQl = `query getModel(
        $id: ID!
        $after: String!
    ) {
        model(id: $id) {
            id
            model
            modelYear
            images(isFeatured: true) {
                filename
            }
            brand {
                id
                brand
                image
            }
            specs(
                first: 1, after: null,
              	_order: {updatedAt: "DESC"}
              	updatedAt: {after: $after}
            ) {
                edges {
                    node {
                        id
                    }
                }
            }
            versions(
                isActive: true
                _order:{ bodyType: "ASC", motor_fuel: "ASC", prices_price: "ASC"}
            ){
                id
                version
                gearbox
                places
                doors
                curbWeight
                gvw
                traction
                tyreFront {
                    tyre
                }
                tyreBack {
                    tyre
                }
                prices(
                    _order: {createdAt: "DESC"}
                ) {
                    edges {
                        node {
                            id
                            createdAt
                            price
                            promo
                        }
                    }
                }
                CF {
                    CF
                }
                motor {
                    power
                    fuel
                    cc
                    cylinder
                    torque
                    valves
                    aspiration
                }
                measures {
                    fuelTank
                    width
                    height
                    length
                    wheelbase
                    trunk
                    trunkMax
                }
                performance {
                    to100
                    maxSpeed
                    emissions
                    mileageCity
                    mileageRoad
                    mileageMix
                }
            }
        }
    }`;

    const variables = {
        id: action.modelId,
        after: getBaseDate(90),
    };
    try {
        yield put({
            type: GET_MODEL_INIT,
        });

        const data = yield call(apiQl, queryQl, variables);
        if (data.errors) {
            yield put({
                type: GET_MODEL_ERROR,
                data: errorParserGraphql(data.errors),
            });
            yield put({
                type: CLIENT_LOG,
                data: {
                    message: errorParserGraphql(data.errors),
                    action: GET_MODEL,
                },
            });
        } else {
            yield put({
                type: GET_MODEL_OK,
                data: data.data.model,
            });
        }
    } catch (error) {
        const isOffline = !!(
            error.response === undefined || error.code === 'ECONNABORTED'
        );
        if (error.response.status === 401) {
            yield put({
                type: LOGOUT_TOKEN_EXPIRED,
            });
        } else if (isOffline) {
            // check if offline event already fired
            localforage.getItem('offline-event-fired').then((value) => {
                if (value === null) {
                    localforage.setItem('offline-event-fired', true);
                }
            });
            yield put({
                type: CHECK_ONLINE_STATUS_ERROR,
                isOnline: false,
            });
        }
    }
}

function* getModelTrims(action) {
    const queryQl = `query getModelTrims(
        $id: ID!
    ) {
        model(id: $id) {
            id
            versions(
                isActive: true
                _order:{ bodyType: "ASC", motor_fuel: "ASC", prices_price: "ASC"}
            ){
                id
                trims(_order: { trim: "ASC"}) {
                    id
                    trim
                    trimType
                }
            }
        }
    }`;

    const variables = {
        id: action.modelId,
    };
    try {
        yield put({
            type: GET_MODEL_TRIMS_INIT,
        });

        const data = yield call(apiQl, queryQl, variables);
        if (data.errors) {
            yield put({
                type: GET_MODEL_TRIMS_ERROR,
                data: errorParserGraphql(data.errors),
            });
            yield put({
                type: CLIENT_LOG,
                data: {
                    message: errorParserGraphql(data.errors),
                    action: GET_MODEL_TRIMS,
                },
            });
        } else {
            yield put({
                type: GET_MODEL_TRIMS_OK,
                data: data.data.model,
            });
        }
    } catch (error) {
        const isOffline = !!(
            error.response === undefined || error.code === 'ECONNABORTED'
        );
        if (error.response.status === 401) {
            yield put({
                type: LOGOUT_TOKEN_EXPIRED,
            });
        } else if (isOffline) {
            // check if offline event already fired
            localforage.getItem('offline-event-fired').then((value) => {
                if (value === null) {
                    localforage.setItem('offline-event-fired', true);
                }
            });
            yield put({
                type: CHECK_ONLINE_STATUS_ERROR,
                isOnline: false,
            });
        }
    }
}

function* getModelsWith4x4() {
    const queryQl = `query getModels4x4(
        $isActive: Boolean!
  	    $traction: String!
        $imageIsFeatured: Boolean!
        ) {
            models(
                isActive: $isActive
                versions_traction_list:[$traction])
            {
    	        id
                model
                modelYear
                images(isFeatured: $imageIsFeatured) {
                    filename
                }
            }
        }`;

    const variables = {
        isActive: true,
        traction: '4x4',
        imageIsFeatured: true,
    };
    try {
        const data = yield call(apiQl, queryQl, variables);
        if (data.errors) {
            yield put({
                type: GET_MODELS_WITH_4X4_ERROR,
                data: errorParserGraphql(data.errors),
            });
            yield put({
                type: CLIENT_LOG,
                data: {
                    message: errorParserGraphql(data.errors),
                    action: GET_MODELS_WITH_4X4,
                },
            });
        } else {
            yield put({
                type: GET_MODELS_WITH_4X4_OK,
                data: data.data.models,
            });
        }
    } catch (error) {
        const isOffline = !!(
            error.response === undefined || error.code === 'ECONNABORTED'
        );
        if (error.response.status === 401) {
            yield put({
                type: LOGOUT_TOKEN_EXPIRED,
            });
        } else if (isOffline) {
            // check if offline event already fired
            localforage.getItem('offline-event-fired').then((value) => {
                if (value === null) {
                    localforage.setItem('offline-event-fired', true);
                }
            });
            yield put({
                type: CHECK_ONLINE_STATUS_ERROR,
                isOnline: false,
            });
        }
    }
}

function* getModelsWithHybridElectric() {
    const queryQl = `query getModelsWithHybridElectric(
        $isActive: Boolean!
  	    $fuels: [String]
        $imageIsFeatured: Boolean!
        ) {
            models(
                isActive: $isActive
                versions_motor_fuel_list: $fuels)
            {
    	        id
                model
                modelYear
                images(isFeatured: $imageIsFeatured) {
                    filename
                }
            }
        }`;

    const variables = {
        isActive: true,
        fuels: ['hybrid', 'electric'],
        imageIsFeatured: true,
    };
    try {
        const data = yield call(apiQl, queryQl, variables);
        if (data.errors) {
            yield put({
                type: GET_MODELS_WITH_HYBRID_ELECTRIC_ERROR,
                data: errorParserGraphql(data.errors),
            });
            yield put({
                type: CLIENT_LOG,
                data: {
                    message: errorParserGraphql(data.errors),
                    action: GET_MODELS_WITH_HYBRID_ELECTRIC,
                },
            });
        } else {
            yield put({
                type: GET_MODELS_WITH_HYBRID_ELECTRIC_OK,
                data: data.data.models,
            });
        }
    } catch (error) {
        const isOffline = !!(
            error.response === undefined || error.code === 'ECONNABORTED'
        );
        if (error.response.status === 401) {
            yield put({
                type: LOGOUT_TOKEN_EXPIRED,
            });
        } else if (isOffline) {
            // check if offline event already fired
            localforage.getItem('offline-event-fired').then((value) => {
                if (value === null) {
                    localforage.setItem('offline-event-fired', true);
                }
            });
            yield put({
                type: CHECK_ONLINE_STATUS_ERROR,
                isOnline: false,
            });
        }
    }
}

// eslint-disable-next-line func-names
export default function* model() {
    yield all([
        takeLatest(GET_MODEL_VERSIONS_WITH_TRIMS, getModelVersionsWithTrims),
        takeLatest(GET_PREVIOUS_MODELS, getPreviousModels),
        takeLatest(GET_MODEL, getModel),
        takeLatest(GET_MODEL_TRIMS, getModelTrims),
        takeLatest(GET_MODELS_WITH_4X4, getModelsWith4x4),
        takeLatest(GET_MODELS_WITH_HYBRID_ELECTRIC, getModelsWithHybridElectric),
    ]);
}
